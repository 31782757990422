.internet-options__bill-container{
    flex-direction: row;
    gap: 5px;
}

.internet-options__bill-container .internet-options__option-container__option {
    width: calc(50% - 2.5px);
    min-width: 100px;
}

.internet-options__bill-container .internet-options__option-container__option .internet-options__option-container__option__card {
    width: 200px;
    height: 200px;
}

@media screen and (max-width: 350px) {
    .internet-options__bill-container{
        flex-direction: column;
    }

    .internet-options__bill-container .internet-options__option-container__option {
        width: 100%;
    }

    .internet-options__bill-container .internet-options__option-container__option .internet-options__option-container__option__card {
        width: 100%;
    }
};