@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('./fonts/gilroy.css');
html {
  font-family: 'Gilroy', sans-serif;
}

.get-started-bg{
  background-image: url(./assets/getstarted_billboard.jpg);
}

.rent-gradient{
  background-image: url(./assets/rents-gradient.svg);
  background-blend-mode: overlay;
  /* background-position: matrix(1, 0, 0, -1, 0, 0); */
}

.bills-gradient{
  background-image: url(./assets/bills-gradient.svg);
}

.onboarding-bg{
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0) 55.14%), url(./assets/interior.jpg);
  background-position: center;
}
.screen-without-header {
  min-height: calc(100vh - 72px)
}


@layer base {
  /* button,.button,input[type='button'],input[type='reset'],input[type='submit'] {
    @apply bg-gray-50;
  } */
:root {
  --font-gilroy: 'Gilroy, sans-serif';


  /* Contextual Colors */
  --primary: 5 74% 61%;
  --primary-lighter: 4 74% 92%;
  --primary-light: 6 74% 81%;
  --primary-dark: 5 46% 41%;
  --primary-darker: 5 46% 20%;

  --secondary: 225 41% 21%;
  --secondary-lighter: 220 11% 84%;
  --secondary-light: 224 12% 47%;
  --secondary-dark: 224 42% 14%;
  --secondary-darker: 227 39% 7%;

  --accent-primary: 229 29% 58%;
  --accent-secondary: 314 56% 82%;
  --accent-tertiary: 219 32% 78%;

  --text-primary: 221 39% 11%;
  --text-secondary: 215 14% 34%;
  --text-inactive: 218 11% 65%;
  --text-inverted: 0 0% 100%;
  --text-success: 163 88% 20%;
  --text-error: 0 70% 35%;
  --text-warning: 47 70% 30%;
  --text-interactive: 10 92% 60%;

  --border: 216 12% 84%;
  --border-light: 220 13% 91%;
  --border-dark: 218 11% 65%;
  --border-interactive: 217 16% 15%;

  --background-foundation: 0 0% 100%;
  --background-secondary: 220 14% 96%;
  --background-offwhite: 210 20% 98%;
  --background-powder: 34 68% 94%;
  --background-purple: 227 83% 95%;
  --background-dark: 215 28% 17%;
  --background-modal: 217 24% 27%;

  --button-primary: 218 17% 18%;
  --button-primary-hover: 216 16% 6%;
  --button-secondary: 220 14% 96%;
  --button-secondary-hover: 220 13% 91%;
  --button-disabled: 220 14% 96%;

  --select-foreground: 210 24% 98%;

  --ring-active: 220 4% 84%;
  --ring-danger: 0 93% 94%;

  /* Colors */
  --neutral-50: 210 20% 98%;
  --neutral-100: 220 14% 96%;
  --neutral-200: 220 13% 91%;
  --neutral-300: 216 12% 84%;
  --neutral-400: 218 11% 65%;
  --neutral-500: 220 9% 46%;
  --neutral-600: 215 14% 34%;
  --neutral-700: 217 19% 27%;
  --neutral-800: 215 28% 17%;
  --neutral-900: 221 39% 11%;

  --space-cadet-25: 220 11% 84%;
  --space-cadet-50: 227 10% 74%;
  --space-cadet-100: 226 10% 61%;
  --space-cadet-200: 224 12% 47%;
  --space-cadet-300: 225 21% 34%;
  --space-cadet-400: 225 41% 21%;
  --space-cadet-500: 225 40% 18%;
  --space-cadet-600: 224 42% 14%;
  --space-cadet-700: 226 41% 11%;
  --space-cadet-800: 227 39% 7%;
  --space-cadet-900: 220 43% 4%;

  --brick-orange-25: 4 74% 92%;
  --brick-orange-50: 5 73% 87%;
  --brick-orange-100: 6 74% 81%;
  --brick-orange-200: 5 74% 74%;
  --brick-orange-300: 5 73% 68%;
  --brick-orange-400: 5 74% 61%;
  --brick-orange-500: 5 49% 51%;
  --brick-orange-600: 5 46% 41%;
  --brick-orange-700: 5 46% 31%;
  --brick-orange-800: 5 46% 20%;
  --brick-orange-900: 5 46% 12%;

  --aquamarine-25: 149 71% 93%;
  --aquamarine-50: 151 69% 88%;
  --aquamarine-100: 150 70% 83%;
  --aquamarine-200: 149 71% 77%;
  --aquamarine-300: 150 70% 71%;
  --aquamarine-400: 150 70% 66%;
  --aquamarine-500: 150 45% 55%;
  --aquamarine-600: 149 37% 44%;
  --aquamarine-700: 150 37% 33%;
  --aquamarine-800: 151 37% 22%;
  --aquamarine-900: 149 37% 13%;

  --powder-400: 34 68% 94%;

  --powder-blue-400: 219 32% 78%;

  --glaucuos-25: 230 29% 92%;
  --glaucuos-100: 230 28% 79%;
  --glaucuos-400: 229 29% 58%;
  --glaucuos-600: 229 20% 39%;

  --pink-lavender-25: 312 56% 96%;
  --pink-lavender-200: 314 57% 88%;
  --pink-lavender-400: 314 56% 82%;
  --pink-lavender-600: 314 15% 55%;

  --yellow-25: 47 100% 92%;
  --yellow-50: 48 100% 87%;
  --yellow-100: 47 100% 79%;
  --yellow-200: 47 100% 73%;
  --yellow-300: 47 100% 66%;
  --yellow-400: 47 100% 59%;
  --yellow-500: 47 70% 49%;
  --yellow-600: 47 69% 39%;
  --yellow-700: 47 70% 30%;
  --yellow-800: 47 70% 20%;
  --yellow-900: 47 70% 12%;

  --green-50: 152 81% 96%;
  --green-100: 149 80% 90%;
  --green-200: 152 76% 80%;
  --green-300: 156 72% 67%;
  --green-400: 158 64% 52%;
  --green-500: 160 84% 39%;
  --green-600: 161 94% 30%;
  --green-700: 163 94% 24%;
  --green-800: 163 88% 20%;
  --green-900: 164 86% 16%;


  --red-50: 0 86% 97%;
  --red-100: 0 93% 94%;
  --red-200: 0 96% 89%;
  --red-300: 0 94% 82%;
  --red-400: 0 91% 71%;
  --red-500: 0 84% 60%;
  --red-600: 0 72% 51%;
  --red-700: 0 74% 42%;
  --red-800: 0 70% 35%;
  --red-900: 0 63% 31%;

  /** Added Colors v1.2 **/
  --dark-blue-25: 220 4% 84%;
  --dark-blue-50: 216 4% 73%;
  --dark-blue-100: 218 4% 59%;
  --dark-blue-200: 218 5% 46%;
  --dark-blue-300: 217 8% 32%;
  --dark-blue-400: 218 17% 18%;
  --dark-blue-500: 217 16% 15%;
  --dark-blue-600: 218 17% 12%;
  --dark-blue-700: 218 17% 9%;
  --dark-blue-800: 216 16% 6%;
  --dark-blue-900: 220 16% 4%;


  --brand-purple-25: 227 83% 95%;
  --brand-purple-50: 226 79% 92%;
  --brand-purple-100: 226 80% 88%;
  --brand-purple-200: 225 82% 85%;
  --brand-purple-300: 226 81% 81%;
  --brand-purple-400: 225 81% 77%;
  --brand-purple-500: 226 43% 64%;
  --brand-purple-600: 225 26% 51%;
  --brand-purple-700: 226 24% 39%;
  --brand-purple-800: 226 24% 26%;
  --brand-purple-900: 227 24% 15%;


  --pastel-green-25: 163 64% 96%;
  --pastel-green-50: 164 62% 93%;
  --pastel-green-100: 165 61% 89%;
  --pastel-green-200: 165 63% 85%;
  --pastel-green-300: 164 62% 82%;
  --pastel-green-400: 165 63% 78%;
  --pastel-green-500: 164 33% 65%;
  --pastel-green-600: 165 19% 52%;
  --pastel-green-700: 165 18% 39%;
  --pastel-green-800: 164 17% 26%;
  --pastel-green-900: 163 17% 16%;


  --brand-orange-25: 10 90% 92%;
  --brand-orange-50: 12 90% 88%;
  --brand-orange-100: 10 92% 80%;
  --brand-orange-200: 10 92% 76%;
  --brand-orange-300: 10 92% 67%;
  --brand-orange-400: 10 92% 60%;
  --brand-orange-500: 10 61% 50%;
  --brand-orange-600: 10 60% 40%;
  --brand-orange-700: 10 60% 30%;
  --brand-orange-800: 11 61% 20%;
  --brand-orange-900: 10 61% 12%;


  --dull-green-25: 160 11% 84%;
  --dull-green-50: 158 12% 74%;
  --dull-green-100: 159 11% 61%;
  --dull-green-200: 160 12% 47%;
  --dull-green-300: 158 22% 34%;
  --dull-green-400: 159 43% 21%;
  --dull-green-500: 159 42% 18%;
  --dull-green-600: 160 42% 14%;
  --dull-green-700: 159 42% 11%;
  --dull-green-800: 158 44% 7%;
  --dull-green-900: 160 43% 4%;

  /* success page background */
  --success-foreground: 221 39% 11%;
  }

  html {
    font-family: var(--font-gilroy);
  }
}

@layer components {
  .PhoneInput{
    @apply w-full  bg-neutral-100 rounded-l-lg rounded-r-lg pl-3 !important;
  }
  .PhoneInput > .PhoneInputCountrySelect{
    @apply rounded-lg bg-neutral-100  ring-offset-primary-light placeholder:text-neutral-400 focus-visible:outline-none focus-visible:ring-4 focus-visible:ring-primary-lighter focus-visible:bg-white focus-visible:ring-offset-0  relative flex items-center space-x-3 border-none px-3 py-2 focus:ring-1 sm:text-base !important;
  }
  .PhoneInput > .PhoneInputInput {
    @apply rounded-lg  bg-neutral-100  ring-offset-primary-light placeholder:text-neutral-400 focus-visible:outline-none focus-visible:ring-4 focus-visible:ring-primary-lighter focus-visible:bg-white focus-visible:ring-offset-0 block w-full rounded-r border-none px-3 py-2 disabled:bg-opacity-50 sm:text-base !important;
  }
}